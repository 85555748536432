.topnavbar {
    background-image: linear-gradient(to left, #ec2121 0%, #f05050 100%);
    direction: rtl;
}


.mr-auto-custome, .mx-auto {
    margin-left: auto !important;
}

.navbar-nav {
    padding-right: 0;
}

.navbar-nav .nav-item + .nav-item {
    margin-right: 0;
    margin-left: inherit;
  }




.aside-container {
    right: 0!important;
    text-align: right;
}


.sidebar-nav {
    direction: rtl
}

.sidebar-nav > li.active {
    border-right-color: #f05050;
    border-left-color: transparent;
}

.sidebar-nav {
    padding-right: 0!important;
}


.sidebar-nav > li {
    border-right: 3px solid transparent;
    transition: border-right-color 0.4s ease;
}

 .section-container {
    direction: rtl!important;
 }

 #margin-dir {
    transition: margin-right 0.2s cubic-bezier(0.35, 0, 0.25, 1)!important;
}








@media only screen and (min-width: 1025px){
    #sidebar-custom:not(.show-scrollbar) {
        margin-left: -17px!important;
        margin-right: 0!important;
        overflow-y: auto;
    }
}





div.dataTables_wrapper div.dataTables_filter {
    text-align: right!important;
    float: right!important;
}

.footer-container{
    text-align: right;
}



.is-to-show-ltr{
    display: none
}

.is-to-show-rtl{
    display: initial
}




.table.table-bordered.dataTable th, table.table-bordered.dataTable td {
    border-left-width: 1px!important;
    border-right-width: 0px!important;
}


#navbar-brand {
    margin-left: 1rem!important;
    margin-right: 0rem!important;
}



.text-dir{
    text-align: right!important;
}


.text-dir-opposite{
    text-align: left!important;
}



.rounded-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}


.rounded-left {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}


.alert-diraction{
    left: 0px!important;
    right: auto!important;
}


#alertsTable_filter{
    float: left!important;
}


#dropdown-alerts,#dropdown-modes{
    right: 0!important;
    width: 185px!important;
    
}

.margin-chekbox{
    margin-left: 5px!important;
    margin-right: 0px!important;
}


#allAlerts,#allModes{
    width: 185px!important;
    text-align: right!important;
}


@media only screen and (max-width: 1366px){
    .filter-alerts-position{
        right: 22%;
    }
    

.filters{
    left:0px;
}



}


@media only screen and (min-width: 1367px){
    .filter-alerts-position{
        right: 15%;
    }
    
    .border-filter{
        right:50px;
    }
    
.filters{
    left: 0px;
}

}

.dt-buttons{
    float: left!important;
}






.aParent div {
    float: right;
    clear: none; 
}

.filter-mode-pos{
    right: 12%!important;
}



// .dropdown-list .multiselect-item-checkbox{
//     padding-right: 2em!important;
//     text-align: right!important; 
// }

.text-align-small{
    text-align: left!important;
}

@media only screen and (min-width: 1441px){
    .filter-position {
       padding-right: 50px!important;
    }
}


@media only screen and (max-width: 1440px){
    .filter-position {
       padding-right: 0px!important;
    }

    .border-filter{
        right:0px;
    }
}


.dropdown-menu{
    right: auto!important;
    left: 0!important;
}

ngb-datepicker.dropdown-menu{
    right: 0!important;
    left: auto!important;
    transform: translate(0px, 35px)!important;
}

.custome-pad .form-control.form-control-sm {
    right: -25px!important;
    width: auto!important;
}

.custome-pad .search-icon-position {
    position: relative;
    right: 7px;
    top: 1px;
    z-index: 9;
}

