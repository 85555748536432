/* search input align media query */

.no-radius-rtl .float-oposite-modal{
    float: left!important;
}

.rtl .float-oposite-global{
    float: left!important;
}

.rtl .float-oposite-text-global{
    text-align: left!important;
}

.rtl .po-oposite{
    left: 15px!important;
    right: none!important;
}

@media only screen and (min-width: 1441px){
    #controllersTable_filter{
        position: absolute;
        top: -51px;
         right: 15px;
    }


    .search-icon-position {
        position: relative;
        right: -5%;
        top: 1px;
        z-index: 9;
    }

     div.dataTables_wrapper div.dataTables_filter input {
    margin-right: 0.5em !important;
    width: 186px;
    right: -42px;
    position: relative;
    padding-right: 1.8rem;
    }


  .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5em !important;
        width: 205px;
        right: -42px;
        position: relative;
        padding-right: 1.8rem;
        }


}


@media only screen and (max-width: 1440px) and (min-width: 1368px)  {
    #controllersTable_filter{
        position: absolute;
        top: -51px;
        right: 15px;
    }


    .search-icon-position {
        position: relative;
        right: -5%;
        top: 1px;
        z-index: 9;
    }

    div.dataTables_wrapper div.dataTables_filter input {
    margin-right: 0.5em !important;
    width: 134px;
    right: -42px;
    position: relative;
    padding-right: 1.8rem;
    }


    .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5em !important;
        width: 153px;
        right: -42px;
        position: relative;
        padding-right: 1.8rem;
        }


}


@media only screen and (max-width: 1367px) and (min-width: 1224px)  {
    #controllersTable_filter{
        position: absolute;
        top: -51px;
        right: 15px;
    }


    .search-icon-position {
        position: relative;
        right: -5%;
        top: 1px;
        z-index: 9;
    }

    div.dataTables_wrapper div.dataTables_filter input {
    margin-right: 0.5em !important;
    width: 117px;
    right: -42px;
    position: relative;
    padding-right: 1.8rem;
    }


    .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5em !important;
        width: 136px;
        right: -42px;
        position: relative;
        padding-right: 1.8rem;
        }
}


@media only screen and (max-width: 1223px) and (min-width: 1024px)  {
    #controllersTable_filter{
        position: absolute;
        top: -99px;
        right: 15px;
    }


    .search-icon-position {
        position: relative;
        right: -5%;
        z-index: 9;
    }

    div.dataTables_wrapper div.dataTables_filter input {
    margin-right: 0.5em !important;
    width: 114px;
    right: -42px;
    position: relative;
    padding-right: 1.8rem;
    }

    .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5em !important;
        width: 139px;
        right: -42px;
        position: relative;
        padding-right: 1.8rem;
        }

}



@media only screen and (max-width: 1023px) and (min-width: 756px)  {
    #controllersTable_filter{
        position: absolute;
        top: -179px;
        right: -2px;
    }


     .search-icon-position{
             position: relative;
             right: 3%;
             z-index: 9;
         }

         div.dataTables_wrapper div.dataTables_filter input {
            margin-right: 0.5em!important;
           
            right: -15%;
             position: relative;
             padding-right: 1.8rem;
         }




         .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
             margin-right: 0.5em!important;
             
             right: -11%;
             position: relative;
             padding-right: 1.8rem;
            }


}

@media only screen and (max-width: 991px) and (min-width: 850px)  {
    div.dataTables_wrapper div.dataTables_filter input {
         width: 240px;
         right: -15%;
       
     }


     .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
          width:286px;
        }

}

@media only screen and (max-width: 851px) and (min-width: 801px)  {
    div.dataTables_wrapper div.dataTables_filter input {
         width: 185px;
         right: -15%;
       
     }


     .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
          width:232px;
        }

}

@media only screen and (max-width: 800px) and (min-width: 786px)  {
    div.dataTables_wrapper div.dataTables_filter input {
         width: 167px;
         right: -15%;
       
     }


     .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
          width:213px;
        }

}


@media only screen and (max-width: 785px) and (min-width: 756px)  {
    div.dataTables_wrapper div.dataTables_filter input {
         width: 154px;
         right: -15%;
       
     }


     .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
          width:202px;
        }

}



@media only screen and (max-width: 755px)  {
    #controllersTable_filter{
        position: absolute;
        top: -513px;
        right: -2px;
    }


     .search-icon-position{
             position: relative;
             right: 3%;
             z-index: 9;
         }

         div.dataTables_wrapper div.dataTables_filter input {
             margin-right: 0.5em!important;
              width:202px;
              right: -11%;
         position: relative;
             padding-right: 1.8rem;
         }
}


.btn-custome-default span {
    padding-right: 3px;
}




.rtl .btn-custome-print-position{
    margin: 5px 110px 0!important;
    direction: ltr!important;
}





@media only screen and (min-width: 992px) {
    .rtl .export-button-position{
        padding-left: 10px;
        padding-right: 0px;
        position: absolute;
        top: 5px;
        left: 0px;
        right: unset;
    }
   
}

@media only screen and (max-width: 991px) {
    .rtl  .export-button-position{
        padding-left: 0px;
        padding-right: 0px;
        position: absolute;
        top: 5px;
        left: 15px;
        right: unset;
    }
 
}

@media only screen and (max-width: 767px) {
    .rtl .export-button-position{
        padding-left: 0px;
        padding-right: 0px;
        position: absolute;
        top: 5px;
        left: 15px;
        right: unset;
    }
 
    .sidebar-toggle{
        top:9px!important;
        right:7px!important;
        width: 41px!important;
    }
    /* .aside-collapsed  #margin-dir-aside-container{
        margin-right:-140px;
    }

     .aside-toggled  #margin-dir-aside-container{
        margin-right:0px;
    } */
}




@media only screen and (max-width: 1199px) {
    .main-dir-oposit{
        direction: ltr!important;
    }
}



@media only screen and (min-width: 1304px) and (max-width: 1199px)  {
    .dashboard-up-tiles{
        white-space: nowrap!important;
     }
    .rtl .dashboard-up-tiles-padding{
         padding-right: 7px!important;
         padding-left: 0!important;
      }

      .text-uppercase {
        text-transform: initial!important;
     }
    }


    .no-radius-rtl .close-my{
        left: -52px!important;
    }

   .rtl .pad-right-10{
        padding-right: 10px!important;
    }
    

    .rtl .marg-right-10{
        margin-right: 10px!important;
    }
 
    .close-position{
    position: relative!important;
    right: 16px!important;
}



.rtl .multiselect-item-checkbox{
    text-align: right!important;
}

 
  