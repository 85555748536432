/* search input align media query */

.no-radius-ltr .float-oposite-modal{
    float: right!important;
}

.ltr .float-oposite-global{
    float: right!important;
}

.ltr .float-oposite-text-global{
    text-align: right!important;
}

.ltr .po-oposite{
    right: 15px!important;
    left: none!important;
}



@media only screen and (min-width: 1441px){
    #controllersTable_filter{
        position: absolute;
        top: -51px;
         left: 15px;
    }


    .search-icon-position {
        position: relative;
        left: -5%;
        top: 1px;
        z-index: 9;
    }

     div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    width: 186px;
    left: -42px;
    position: relative;
    padding-left: 1.8rem;
    }


     .aside-collapsed  div.dataTables_wrapper div.dataTables_filter input {
        margin-left: 0.5em !important;
        width: 205px;
        left: -42px;
        position: relative;
        padding-left: 1.8rem;
        }


}


@media only screen and (max-width: 1440px) and (min-width: 1368px)  {
    #controllersTable_filter{
        position: absolute;
        top: -51px;
         left: 15px;
    }


    .search-icon-position {
        position: relative;
        left: -5%;
        top: 1px;
        z-index: 9;
    }

    div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    width: 134px;
    left: -42px;
    position: relative;
    padding-left: 1.8rem;
    }


    .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
        margin-left: 0.5em !important;
        width: 153px;
        left: -42px;
        position: relative;
        padding-left: 1.8rem;
        }


}


@media only screen and (max-width: 1367px) and (min-width: 1224px)  {
    #controllersTable_filter{
        position: absolute;
        top: -51px;
         left: 15px;
    }


    .search-icon-position {
        position: relative;
        left: -5%;
        top: 1px;
        z-index: 9;
    }

    div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    width: 117px;
    left: -42px;
    position: relative;
    padding-left: 1.8rem;
    }


    .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
        margin-left: 0.5em !important;
        width: 136px;
        left: -42px;
        position: relative;
        padding-left: 1.8rem;
        }
}


@media only screen and (max-width: 1223px) and (min-width: 1024px)  {
    #controllersTable_filter{
        position: absolute;
        top: -99px;
        left: 15px;
    }


    .search-icon-position {
        position: relative;
        left: -5%;
        z-index: 9;
    }

    div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    width: 114px;
    left: -42px;
    position: relative;
    padding-left: 1.8rem;
    }

    .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
        margin-left: 0.5em !important;
        width: 139px;
        left: -42px;
        position: relative;
        padding-left: 1.8rem;
        }

}


@media only screen and (max-width: 1023px) and (min-width: 756px)  {
    #controllersTable_filter{
        position: absolute;
        top: -179px;
        left: -2px;
    }


     .search-icon-position{
             position: relative;
             left: 3%;
             z-index: 9;
         }

         div.dataTables_wrapper div.dataTables_filter input {
            margin-left: 0.5em!important;
           
             left: -15%;
             position: relative;
             padding-left: 1.8rem;
         }




         .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
             margin-left: 0.5em!important;
             
             left: -11%;
             position: relative;
             padding-left: 1.8rem;
            }


}

@media only screen and (max-width: 991px) and (min-width: 850px)  {
    div.dataTables_wrapper div.dataTables_filter input {
         width: 240px;
         left: -15%;
       
     }


     .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
          width:286px;
        }

}

@media only screen and (max-width: 851px) and (min-width: 801px)  {
    div.dataTables_wrapper div.dataTables_filter input {
         width: 185px;
         left: -15%;
       
     }


     .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
          width:232px;
        }

}

@media only screen and (max-width: 800px) and (min-width: 786px)  {
    div.dataTables_wrapper div.dataTables_filter input {
         width: 167px;
         left: -15%;
       
     }


     .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
          width:213px;
        }

}


@media only screen and (max-width: 785px) and (min-width: 756px)  {
    div.dataTables_wrapper div.dataTables_filter input {
         width: 154px;
         left: -15%;
       
     }


     .aside-collapsed div.dataTables_wrapper div.dataTables_filter input {
          width:202px;
        }

}




@media only screen and (max-width: 755px)  {
    #controllersTable_filter{
        position: absolute;
        top: -513px;
        left: -2px;
    }


     .search-icon-position{
             position: relative;
             left: 3%;
             z-index: 9;
         }

         div.dataTables_wrapper div.dataTables_filter input {
             margin-left: 0.5em!important;
              width:202px;
             left: -11%;
         position: relative;
             padding-left: 1.8rem;
         }
}



.btn-custome-default span {
    padding-left: 3px;
}


.ltr .btn-custome-print-position{
    margin: 5px 90px 0!important;
}


@media only screen and (min-width: 992px) {
    .ltr .export-button-position{
        padding-right: 10px;
        padding-left: 0px;
        position: absolute;
        top: 5px;
        right: 0px;
        left: unset;
    }
   
}

@media only screen and (max-width: 991px) {
    .ltr .export-button-position{
        padding-left: 0px;
        padding-right: 0px;
        position: absolute;
        top: 5px;
        right: 15px;
        left: unset;
    }

}



@media only screen and (max-width: 767px) {
    .ltr .export-button-position{
        padding-left: 0px;
        padding-right: 0px;
        position: absolute;
        top: 5px;
        right: 15px;
        left: unset;
    }

    

    /* .aside-collapsed  #margin-dir-aside-container{
        margin-left:-140px;
    }

     .aside-toggled  #margin-dir-aside-container{
        margin-left:0px;
    } */
}






@media only screen and (max-width: 1199px) {
    .main-dir-oposit{
        direction: rtl!important;
    }
}



@media only screen and (max-width: 1304px) and (min-width: 1199px)  {
   .dashboard-up-tiles{
       white-space: nowrap!important;
    }
   .ltr .dashboard-up-tiles-padding{
        padding-left: 7px!important;
        padding-right: 0!important;
     }

     .text-uppercase {
        text-transform: initial!important;
    }
   }

  .no-radius-ltr .close-my{
    right: -52px!important;
}

 .close-position{
    position: relative!important;
    left: 0px!important;
}



.ltr .multiselect-item-checkbox{
    text-align: left!important;
}