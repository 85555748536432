.width-5 {
    width: 5px!important;
}

.width-25 {
    width: 25px!important;
}

.data-table-icon-position-search {
    position: relative;
    top: 4px;
}

.help-alert {
    padding: 0px!important;
}

.padding-sides {
    padding: 0 15px;
}

.add-user-pos {
    position: absolute;
    top: 16px;
}

.no-radius-rtl .modal-content {
    border-radius: 0!important;
    border: none !important;
    text-align: right!important;
    direction: rtl!important;
}

.no-radius-rtl .modal-content .mr-auto, .mx-auto {
    margin-left: auto !important;
    margin-right: 0!important;
}

.no-radius-ltr .modal-content .mr-auto, .mx-auto {
    margin-right: auto !important;
    margin-left: 0!important;
}

.no-radius-ltr .modal-content .text-dir-opposite {
    text-align: right!important;
}

.no-radius-rtl .modal-content .text-dir-opposite {
    text-align: left!important;
}

.no-radius-rtl .modal-content .nav {
    padding-right: 0!important;
}

.no-radius-ltr .modal-content .nav {
    padding-left: 0!important;
}

.no-radius-ltr .modal-content {
    border-radius: 0!important;
    border: none !important;
    text-align: left!important;
    direction: ltr!important;
}

.modal-content .form-check {
    padding: 0px!important;
}

#modal-body-height {
    padding: 0px!important;
    height: 75vh!important;
}

#modal-body-height .controller-details-info {
    padding: .5rem!important;
}

.tabs {
    padding: 0px!important;
}

.table-pagination .pagination {
    font-size: 0.95rem!important;
}

/* .nav-tabs>.nav-item>.nav-link.active, .nav-tabs>.nav-item>.nav-link.active:hover, .nav-tabs>.nav-item>.nav-link.active:focus {
    color: #C40B1E !important;
    font-weight: 400;
} */

.text-size-2-75 {
    font-size: 2.75em;
    font-weight: 300;
}

.text-size-2 {
    font-size: 2em;
    font-weight: 300;
}

.cursor {
    cursor: pointer!important;
}

.margin-top-5 {
    margin-top: 5px!important;
}

.positioning-header-modal-controolers {
    margin-top: -7px!important;
    margin-bottom: -25px;
}

agm-map {
    height: 511px;
}

.lang-dropdown {
    background-color: transparent!important;
    color: white!important;
    margin-top: 9px!important;
    border: none!important;
}

.lang-dropdown option {
    color: #929292!important;
}

body.modal-open .wrapper {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
    /* filter: url("https://gist.githubusercontent.com/amitabhaghosh197/b7865b409e835b5a43b5/raw/1a255b551091924971e7dee8935fd38a7fdf7311/blur".svg#blur); */
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='10');
}

.text-size-1-5 {
    font-size: 1.5em!important;
}

.pad-top-2 {
    padding-top: 2px;
}

.close {
    padding: 0 10px!important;
}

.direction-rtl {
    direction: rtl!important;
}

.direction-ltr {
    direction: ltr!important;
}

.lang-dropdown {
    padding: 0.375rem 0rem!important;
}

.btn-secondary:not(:disabled):not(.disabled).active, .show>.btn-secondary.dropdown-toggle {
    color: #C40B1E !important;
}

.btnGroup-position {
    position: absolute!important;
    top: 53px;
    right: 237px;
    z-index: 99999;
    cursor: pointer;
}

.alert {
    color: #C40B1E;
}

.filters {
    background-color: white;
    padding: 15px;
    width: 45%;
    z-index: 9999;
    box-shadow: 0 1px 5px #959595;
    -webkit-box-shadow: 0 1px 5px #959595;
    -moz-box-shadow: 0 1px 5px #959595;
    border-bottom: 1px solid #eeeeee;
    position: absolute;
    top: 90px;
}

.padding-bottom-5 {
    padding-bottom: 5px!important;
}

.padding-bottom-15 {
    padding-bottom: 15px!important;
}

.padding-bottom-10 {
    padding-bottom: 10px!important;
}

.padding-top-10 {
    padding-top: 10px!important;
}

.filters-button {
    border-top: 1px solid #eeeeee;
    padding: 9px 11px;
    z-index: 99999;
    padding-bottom: 40px;
    border-bottom: none!important;
    box-shadow: 0px -6px 16px -13px #919191;
    -webkit-box-shadow: 0px -6px 16px -13px #919191;
    -moz-box-shadow: 0px -6px 16px -13px #919191;
    background-color: white;
}

.filter-alerts-position, .filter-mode-position {
    position: absolute;
    top: 15px;
    z-index: 99999;
}

/* #card-body-controllers{
    padding: 44px 15px;
    padding-bottom: 5px;
} */

#card-body, .card-default.table-wrapper {
    border-top-width: 1px!important;
}

.table-filters-divider {
    border-bottom: 3px solid #cfdbe2;
    height: 10px;
}

.table-margin-top {
    margin-top: -6px!important;
}

.padding-none {
    padding: 0px!important;
}

.no-border-side {
    border-left: none!important;
    border-right: none!important;
}

.lead-1 {
    font-size: 1rem;
    font-weight: 300;
}

.text-size-1-75 {
    font-size: 1.75em;
    font-weight: 300;
}

.x-position {
    padding-top: 0px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #e8e8e8;
    margin-top: 17px;
    margin-right: 6px;
    margin-left: 6px;
}

.pad-top-30 {
    padding-top: 30px;
}

#modal-header-height {
    padding: 0.7rem 1rem!important;
}

.btn-default-custome {
    background-color: #CECECE!important;
    color: white!important;
}

#usersTable_wrapper .dt-button-collection, #controllersTable_wrapper .dt-button-collection, #clientsTable_wrapper .dt-button-collection {
    min-width: 5rem!important;
    margin: 5px 110px!important;
}

#clientsTable_filter, #alertsTable_filter, #flushesTable_filter, #alarmsReportTable_filter, #usersTable_filter {
    position: absolute;
}

.custome-width-175 {
    width: 175px!important;
}

.dropdown-btn {
    border-color: #e3e3e3!important;
}

.custome-width-175 .selected-item {
    border: none!important;
    background-color: transparent!important;
    color: #656565!important;
    border: radius 0!important;
    background: transparent!important;
    padding: 0px!important;
    margin-right: 0px!important;
}

.custome-width-175 .selected-item:hover {
    box-shadow: none!important;
}

.text-size-13 {
    font-size: 13px!important;
}

.icon-printer:before {
    font-size: 1.15em!important;
}

.btn-custome-default {
    color: #333!important;
    background-color: #fff!important;
    border: none!important;
    font-size: 0.875rem!important;
    top: -5px!important;
    padding: 5px!important;
}

.btn-custome-default span {
    font-family: "Source Sans Pro", sans-serif!important;
    color: #656565;
    font-size: 0.875rem;
    font-weight: 400!important;
    padding: 0px 3px 0 7px!important;
}

.btn-custome-default:focus {
    color: #333!important;
    background-color: transparent!important;
    border: none!important;
}

.btn-custome-default:hover {
    color: #333!important;
    background-color: transparent!important;
    border: none!important;
}

.btn-custome-default:active {
    color: #333!important;
    background-color: transparent!important;
    border: none!important;
}

/* .dropdown-btn{
    padding: 6px 7px!important;
} */

@media only screen and (max-width: 1024px) {
    .border-filter {
        display: none!important;
    }
    .filter-position {
        z-index: 99999;
        cursor: pointer;
        font-size: 0.875rem!important;
        font-weight: 500!important;
        font-family: "Source Sans Pro", sans-serif;
        white-space: nowrap;
    }
    .export-button-position {
        z-index: 99999;
        white-space: nowrap;
    }
    .text-align-small {
        text-align: right!important;
    }
    .filter-button-position {
        position: absolute;
        top: 30px;
        z-index: 99999;
        white-space: nowrap;
    }
    .width-small {
        width: inherit!important;
    }
    .filters {
        top: 170px;
        width: 90%;
    }
}

.button-width-150 {
    width: 125px;
}

@media only screen and (max-width: 1366px) and (min-width: 1224px) {
    .filters {
        top: 90px;
        width: 50%;
    }
}

@media only screen and (max-width: 1223px) and (min-width: 1024px) {
    .filters {
        top: 90px;
        width: 50%;
    }
}

/* @media only screen and (max-width: 768px){


    .filter-button-position{
        position: absolute;
        top: 0px;
        z-index: 99999;
        white-space: nowrap;
    }
} */

@media only screen and (min-width: 1025px) {
    .filter-button-position {
        position: absolute;
        top: 30px;
        z-index: 99999;
        white-space: nowrap;
    }
    .export-button-position {
        /* position: absolute;
        top: 38px; */
        z-index: 99999;
        white-space: nowrap;
    }
    .filter-position {
        /* position: absolute;
        top: 38px; */
        z-index: 99999;
        cursor: pointer;
        text-align: center;
        font-size: 0.875rem!important;
        font-weight: 500!important;
        font-family: "Source Sans Pro", sans-serif;
        white-space: nowrap;
    }
}

.dataTables_length label {
    margin: unset;
}

.dataTables_length {
    margin-top: 5px;
}

.border-filter {
    height: 55px;
    position: absolute;
    bottom: -5px;
    border-right: 1px solid #dfdfdf;
    margin: 0 -5px;
}

.hide {
    visibility: hidden;
    position: absolute;
}

.hide-elmn {
    display: none!important;
}

.version-text {
    font-size: 0.875rem;
    color: white;
}

.content-heading {
    padding-bottom: 0px!important;
}

#AdvFilters .dropdown-list {
    display: none;
}

@media only screen and (min-width: 1367px) {
    .content-wrapper .container-fluid-custome {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 1366px) {
    .aside-collapsed .content-wrapper .container-fluid-custome {
        padding-left: 10px;
        padding-right: 10px;
    }
    .content-wrapper .container-fluid-custome {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.ngx-select {
    min-height: 35px!important;
}

.center-bottom {
    position: absolute;
    bottom: 0;
    margin: auto;
    width: 100%;
}

.pad-top-35 {
    padding-top: 35px!important;
}

.ngx-select__toggle:hover {
    background-color: white!important;
}

.margin-0 {
    margin: 0px!important;
}

.pad-10 {
    padding: 10px!important;
}

.dashboard-tiles-custom-text-size {
    font-size: 0.775rem!important;
}

.list-group-no-padding {
    padding: 0px!important;
}

.dataTables_empty {
    display: none!important;
}

.position-spiner-tables {
    position: absolute!important;
    left: 48.5%!important;
    padding-top: 13px!important;
    color: #ED2626!important;
    z-index: 99999999!important;
}

.table-filters-divider .dataTables_processing {
    display: none!important;
}

.m-t-1 {
    margin-top: 1px!important;
}

.font-size-12 {
    font-size: 12px!important;
}

.controllerDetailsTabset .tab-content {
    padding: 0;
}

.controllerDetailsTabset .nav-tabs {
    background-color: #EDF1F2!important;
    border-top: 1px solid #e6e6e6!important;
}

/* .controller-details{
    font-size: 1.05rem!important;
} */

.base-info {
    font-size: 1.05em!important;
}

.nav-link {
    border-top: none!important;
}

.close-my {
    position: absolute!important;
    top: -17px!important;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.category-name {
    font-size: 1em!important;
}

.text-slim-med {
    font-weight: 400!important;
}

.pad-top-30-allarms {
    padding-top: 30px!important;
}

@media only screen and (max-width: 1199px) {
    .pad-top-30-allarms {
        padding-top: 0px!important;
    }
}

.lead-divider {
    /* font-size: 1.5rem!important; */
    font-weight: 100!important;
}

.no-wrap {
    white-space: nowrap!important;
}

.pad-right-left-17 {
    padding: 0 17px!important;
}

.marg-right-lrft-5 {
    margin: 0 5px!important;
    color: #dddddd!important;
    position: relative!important;
    top: -3px!important;
}

.pad-top-9 {
    padding-top: 9px!important;
}

.button-width-apply-sahboard {
    width: 125px;
}

.pageLengthDesc {
    padding: 0px 0px!important;
}

@media only screen and (max-width: 1590px) and (min-width:1441px) {
    .button-width-apply-sahboard {
        width: 100px!important;
    }
    .aside-collapsed .button-width-apply-sahboard {
        width: 125px!important;
    }
}

@media only screen and (max-width: 1440px) and (min-width: 1200px) {
    .button-width-apply-sahboard {
        width: 65px!important;
    }
    .aside-collapsed .button-width-apply-sahboard {
        width: 90px!important;
    }
    .pageLengthDesc {
        padding: 0px 35px!important;
    }
}

.lead-400 {
    font-weight: 400;
    font-size: 1.25rem;
    color: black;
}

.lead-400-r-1 {
    font-weight: 400;
    font-size: 1rem;
    color: black;
}

.m-b-3 {
    margin-bottom: 3px!important;
}

.agm-info-window-content {
    overflow: hidden!important;
    width: 360px;
}

@media only screen and (min-width: 992px) {
    .text-center-no-small {
        text-align: center!important;
    }
}

@media only screen and (max-width: 991px) {
    .text-center-no-small {
        text-align: inherit!important;
    }
}

#check-box-alignment {
    margin: 0 5px!important;
}

#check-box-div-alignment {
    margin: 0 -5px!important;
}

/* upload button custome */

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

#upload {
    cursor: pointer;
}

.btn-my {
    border: 1px solid #c5c6c7;
    color: #c5c6c7;
    background-color: white;
    height: 35px;
    width: 229px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.img-size {
    width: 64px;
    height: 64px;
}

.loadder-continer {
    /* border: #DFDFDF solid 1px; */
    height: 513px;
    /* background-color: #FFFFFF; */
    border-radius: 3px;
    background-image: url("/assets/img/map/snazzy-image.png");
    /* filter: blur(4px);
                -webkit-filter: blur(4px); */
    opacity: 0.2;
    filter: alpha(opacity=20);
}

.dotCustome {
    width: 2.5%;
    float: left;
    position: relative;
    top: 18px;
    padding: 0 1px;
}

.inputCustome {
    width: 23%;
    float: left;
    text-align: center;
    padding: 2px!important;
}

@media only screen and (max-width: 1025px) {
    .modal-xl .modal-content {
        min-width: 100%!important;
        margin: 0!important;
        height: 100%!important;
    }
    .modal-xl {
        min-width: 100%!important;
        margin: 0!important;
        height: 100%!important;
    }
    #modal-body-height {
        height: 94vh!important;
    }
}

.controller-details-tabs .card {
    margin-bottom: 0!important;
    border: 1px solid rgba(0, 0, 0, .125)!important;
}

.configurations-custom-modal .modal-lg {
    max-width: 560px!important;
}

.configurations-custom-modal .switch span:after {
    display: none!important;
}

.configurations-custom-modal .switch span {
    width: auto!important;
    height: auto!important;
}

.configurations-custom-modal fieldset {
    padding-bottom: 2px!important;
    border-bottom: 1px dashed #eee;
    margin-bottom: 0px!important;
}

.configurations-custom-modal .checkmark:after {
    left: 1.27em!important;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 15px;
    background-color: #e2e6e6;
}

.dark-scrollbar ::-webkit-scrollbar-thumb, .popover-body ::-webkit-scrollbar-thumb {
    background-color: #878787;
}

@media(max-width: 1200px) {
    ::-webkit-scrollbar-thumb {
        background-color: #878787;
    }
}

/* .rtl button[title="Previous month"] .ngb-dp-navigation-chevron {
                    -webkit-transform: rotate(45deg)!important;
                    transform: rotate(45deg)!important;
                }


                .rtl button[title="Next month"] .ngb-dp-navigation-chevron {
                    -webkit-transform: rotate(-135deg)!important;
                    transform: rotate(-135deg)!important;
                }



                .ltr button[title="Next month"] .ngb-dp-navigation-chevron {
                    -webkit-transform: rotate(45deg)!important;
                    transform: rotate(45deg)!important;
                }

                .ltr button[title="Previous month"] .ngb-dp-navigation-chevron {
                    -webkit-transform: rotate(-135deg)!important;
                    transform: rotate(-135deg)!important;
                } */

.ltr .ngb-dp-navigation-chevron {
    -webkit-transform: rotate(-135deg)!important;
    transform: rotate(-135deg)!important;
}

.ltr .right .ngb-dp-navigation-chevron {
    -webkit-transform: rotate(45deg)!important;
    transform: rotate(45deg)!important;
}

.rtl .right .ngb-dp-navigation-chevron {
    -webkit-transform: rotate(-135deg)!important;
    transform: rotate(-135deg)!important;
}

.rtl .ngb-dp-navigation-chevron {
    -webkit-transform: rotate(45deg)!important;
    transform: rotate(45deg)!important;
}

.font-asowem-med {
    font-size: 20px!important;
}

.circle {
    margin: 0!important;
    border: none!important;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.lang:hover {
    cursor: pointer;
}

#overview-tab-panel {
    overflow: hidden!important;
}

@media(max-width: 1025px) {
    #overview-tab-panel {
        overflow: auto!important;
    }
}

table {
    width: inherit!important;
}

.img-size-32 {
    width: 32px!important;
    height: 32px!important;
}

.modal-controller-title {
    position: relative!important;
    left: -4px!important;
    right: -4px!important;
    font-weight: 400!important;
}

.modal-font-weight-title {
    font-weight: 400!important;
}

.content-wrapper {
    overflow: hidden;
}

.item2 {
    direction: initial;
}

#thresholdsReportTable_filter {
    position: absolute!important;
}

.content-wrapper {
    padding-bottom: 105px!important;
}

.mat-form-field-underline{
  display: none;
}
.mat-form-field-wrapper{
  border: 1px solid black;
}
.mat-form-field-label-wrapper{
  transform: translateY(-25%)
}
