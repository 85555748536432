.topnavbar {
    // background-image: linear-gradient(to right, #ec2121 0%, #f05050 100%);
    direction: ltr;
}

  .mr-auto-custome, .mx-auto {
    margin-right: auto !important;
}

.navbar-nav {
    padding-left: 0;
}

.aside-container {
    left: 0!important;
    text-align: left;
}


.sidebar-nav {
    direction: ltr
}

.sidebar-nav > li.active {
    border-left-color: #f05050;
    border-right-color: transparent;
}


.sidebar-nav {
    padding-left: 0!important;
}

.sidebar-nav > li {
    border-left: 3px solid transparent;
    transition: border-left-color 0.4s ease;
}


 .section-container {
   direction: ltr!important;
}


 #margin-dir {
    transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1)!important;
}




@media only screen and (min-width: 1025px){
    #sidebar-custom:not(.show-scrollbar) {
        margin-right: -17px!important;
    }
    
}

.footer-container{
    text-align: left;
}





div.dataTables_wrapper div.dataTables_filter {
    text-align: left!important;
    float: left!important;
}



 .is-to-show-rtl{
    display: none
}
.is-to-show-ltr{
    display: initial
}


#navbar-brand {
    margin-right: 1rem!important;
    margin-left: 0rem!important;
}



.text-dir{
    text-align: left!important;
}


.text-dir-opposite{
    text-align: right!important;
}

.alert-diraction{
    right: 0px!important;
    left: auto!important;
}


#dropdown-alerts,#dropdown-modes{
    left: 0!important;
    width: 185px!important;
}

.margin-chekbox{
    margin-right: 5px!important;
    margin-left: 0px!important;
}


#allAlerts,#allModes{
    width: 185px!important;
    text-align: left!important;
}






@media only screen and (max-width: 1366px){
    .filter-alerts-position{
        left: 22%;
    }


    // .search-icon-position{
    //     position: relative;
    //     left: 3%;
    //     z-index: 9;
    // }
    
   
    
    
    // div.dataTables_wrapper div.dataTables_filter input {
    //     margin-left: 0.5em!important;
    //      width:202px;
    //     left: -11%;
    //     position: relative;
    //     padding-left: 1.8rem;
    // }

    // .filter-position{
    //     right: 11%;
    // }
    
    .filters{
        right: 0px;
    }

  
   
}






@media only screen and (min-width: 1367px){
    .filter-alerts-position{
        left: 15%;
    }
    
 
    
    .border-filter{
        left:50px;
    }
    
    
    // }

    .filters{
        right: 0px;
    }

}




.dt-buttons{
    float: right!important;
}








.aParent div {
    float: left;
    clear: none; 
}



.filter-mode-pos{
    left: 12%!important;
}



// .dropdown-list .multiselect-item-checkbox{
//     padding-left: 2em!important;
//     text-align: left!important;
    
// }



.text-align-small{
    text-align: right!important;
}


@media only screen and (min-width: 1441px){
    .filter-position {
       padding-left: 50px!important;
    }
}


@media only screen and (max-width: 1440px){
    .filter-position {
       padding-left: 0px!important;
    }

    .border-filter{
        left:0px;
    }
}

.dropdown-menu{
    left: auto!important;
    right: 0!important;
}

ngb-datepicker.dropdown-menu{
    left: 0!important;
    right: auto!important;
    transform: translate(0px, 35px)!important;

}

.custome-pad .form-control.form-control-sm {
    left: -25px!important;
    width: auto!important;
}

.custome-pad .search-icon-position {
    position: relative;
    left: 7px;
    top: 1px;
    z-index: 9;
}




