/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~simple-line-icons/css/simple-line-icons.css';



   
 //== Bootstrap
 @import "./app/shared/styles/bootstrap.scss";
 //@import "../node_modules/bootstrap-v4-rtl/scss/bootstrap-rtl.scss";


//== Application
@import "./app/shared/styles/app.scss";



.rtl {
    @import "./app/shared/styles/rtl.css";
    @import "./app/shared/styles/_rtl.scss";
   }
   
   .ltr {
    @import "./app/shared/styles/ltr.css";
    @import "./app/shared/styles/_ltr.scss";
   }
